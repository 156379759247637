var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "basic-container",
    [
      _c("avue-crud", {
        ref: "crud",
        attrs: {
          option: _vm.option,
          data: _vm.data,
          page: _vm.page,
          "before-open": _vm.beforeOpen,
          permission: _vm.permissionList,
          "table-loading": _vm.loading,
        },
        on: {
          "update:page": function ($event) {
            _vm.page = $event
          },
          "row-update": _vm.rowUpdate,
          "row-save": _vm.rowSave,
          "row-del": _vm.rowDel,
          "search-change": _vm.searchChange,
          "search-reset": _vm.searchReset,
          "selection-change": _vm.selectionChange,
          "current-change": _vm.currentChange,
          "size-change": _vm.sizeChange,
          "refresh-change": _vm.refreshChange,
          "on-load": _vm.onLoad,
        },
        scopedSlots: _vm._u([
          {
            key: "nameSearch",
            fn: function (ref) {
              var row = ref.row
              return [
                _c(
                  "el-select",
                  {
                    attrs: { placeholder: "请选择品牌名称", size: "small" },
                    model: {
                      value: row.name,
                      callback: function ($$v) {
                        _vm.$set(row, "name", $$v)
                      },
                      expression: "row.name",
                    },
                  },
                  _vm._l(_vm.records, function (item) {
                    return _c("el-option", {
                      key: item.id,
                      attrs: { label: item.name, value: item.name },
                    })
                  }),
                  1
                ),
              ]
            },
          },
          {
            key: "menuLeft",
            fn: function (ref) {
              var size = ref.size
              return [
                _c(
                  "el-upload",
                  {
                    attrs: {
                      "auto-upload": false,
                      "show-file-list": false,
                      action: "action",
                      "on-change": _vm.handleChange,
                    },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          size: size,
                          icon: "el-icon-upload2",
                        },
                      },
                      [_vm._v("导入")]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
        model: {
          value: _vm.form,
          callback: function ($$v) {
            _vm.form = $$v
          },
          expression: "form",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }