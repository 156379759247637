<template>
  <basic-container>
    <avue-crud
      :option="option"
      :data="data"
      :page.sync="page"
      :before-open="beforeOpen"
      :permission="permissionList"
      :table-loading="loading"
      v-model="form"
      ref="crud"
      @row-update="rowUpdate"
      @row-save="rowSave"
      @row-del="rowDel"
      @search-change="searchChange"
      @search-reset="searchReset"
      @selection-change="selectionChange"
      @current-change="currentChange"
      @size-change="sizeChange"
      @refresh-change="refreshChange"
      @on-load="onLoad"
    >
      <template slot-scope="{ row }" slot="nameSearch">
        <el-select v-model="row.name" placeholder="请选择品牌名称" size="small">
          <el-option
            v-for="item in records"
            :key="item.id"
            :label="item.name"
            :value="item.name"
          >
          </el-option>
        </el-select>
      </template>
      <template slot="menuLeft" slot-scope="{ size }">
        <el-upload
          :auto-upload="false"
          :show-file-list="false"
          action="action"
          :on-change="handleChange"
        >
          <el-button type="primary" :size="size" icon="el-icon-upload2"
            >导入</el-button
          >
        </el-upload>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import { mapGetters } from "vuex";
import {
  getBrandList,
  remove,
  add,
  update,
} from "@/api/uav/brandManagement";
export default {
  name: 'brandManagement',
  data() {
    // 验证邮箱
    var validateEmail = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入邮箱"));
      } else if (
        /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(value)
      ) {
        callback();
      } else {
        callback(new Error("输入的邮箱不合法"));
      }
    };
    // 验证电话
    var validateTel = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入电话"));
      } else if (/^1[3456789]\d{9}$/.test(value)) {
        callback();
      } else {
        callback(new Error("输入的电话号码不合法"));
      }
    };
    return {
      form: {},
      search: {},
      records: [],
      selectionList: [],
      loading: true,
      page: {
        pageSize: 10,
        total: 1,
        currentPage: 1,
        // layout: "sizes, pager"
      },
      data: [
        // {
        //   name: "",
        //   address: "四川省成都市武侯区巴拉巴拉巴拉巴拉巴拉巴拉巴。。。。",
        //   phone: "12345674323",
        //   email: "@qwe"
        // }
      ],
      option: {
        height:'auto',
        calcHeight: 10,
        viewBtn: true,
        excelBtn: true,
        searchslot: true,
        index: true,
        indexLabel: "序号",
        selection: true,
        border: true,
        dialogWidth: "45%",
        viewTitle: "查看品牌",
        editTitle: "编辑品牌",
        addTitle: "新增品牌",
        searchBtnText: "搜索",
        column: [
          // {
          //   label: "序号",
          //   prop: "index",
          //   hide: true,
          //   addDisplay: false,
          //   editDisplay: false
          // },
          {
            label: "品牌名称",
            prop: "name",
            search: true,
            // type: "select",
            // searchType: "select",
            rules: [
              {
                required: true,
                message: "请输入品牌名称",
                trigger: "blur",
              },
            ],
          },
          {
            label: "品牌code",
            prop: "code",
            type: "select",
            dicUrl: "/api/blade-system/dict-biz/dictionary-tree?code=manufacturer_type",
            props: {
              label: "dictKey",
              value: "dictKey",
            },
            rules: [
              {
                required: true,
                message: "请输入品牌code",
                trigger: "blur",
              },
            ],
          },
          {
            label: "是否关联实体中心",
            prop: "type",
            type: "switch",
            dicData: [
              {
                label: "否",
                value: 2,
              },
              {
                label: "是",
                value: 1,
              },
            ],
            valueType: "number",
            // searchType: "select",
            rules: [
              {
                required: true,
                message: "请选择品牌类型",
                trigger: "blur",
              },
            ],
          },
          {
            label: "地址",
            width: "280px",
            prop: "address",
            overHidden: "true",
            rules: [
              {
                required: true,
                message: "请输入地址",
                trigger: "blur",
              },
            ],
          },
          {
            label: "电话",
            prop: "phone",
            rules: [
              {
                required: true,
                validator: validateTel,
                trigger: "change",
              },
            ],
          },
          {
            label: "邮箱",
            prop: "email",
            rules: [
              {
                required: true,
                validator: validateEmail,
                trigger: "change",
              },
            ],
          },
          {
            label: "更新时间",
            prop: "updateTime",
            hide: true,
            addDisplay: false,
            editDisplay: false,
          },
          {
            label: "备注",
            prop: "remarks",
            type: "textarea",
            hide: true,
            span: 24,
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["permission"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.brandManagement_add, false),
        viewBtn: this.vaildData(this.permission.brandManagement_view, false),
        delBtn: this.vaildData(this.permission.brandManagement_delete, false),
        editBtn: this.vaildData(this.permission.brandManagement_edit, false),
      };
    },
  },
  mounted() {
    this.getRecords();
  },
  methods: {
    validate() {
      this.$refs.crud.validate((valid, done, msg) => {
        if (valid) {
          done();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    getRecords() {
      let current = this.page.currentPage;
      let size = this.page.pageSize;
      let params = {};
      getBrandList(current, size, Object.assign(params, this.query)).then(
        (res) => {
          this.records = res.data.data.records;
        }
      );
    },
    handleChange(file, fileLis) {
      console.log(file, "file");
      // this.$Export.xlsx(file.raw).then(data => {
      //   this.list = data.results;
      // });
    },
    // beforeOpen(done, type) {
    //   done();
    // },
    rowUpdate(row, index, done, loading) {
      update(row).then(
        (res) => {
          // console.log(res.data, "update");
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          done();
        },
        (error) => {
          loading();
          console.log(error);
        }
      );
    },
    rowSave(row, done, loading) {
      add(row).then(
        (res) => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          done();
        },
        (error) => {
          loading();
          window.console.log(error);
        }
      );
    },
    rowDel(row, index, done, loading) {
      this.$confirm("确定删除该数据?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return remove(row.id);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
        });
    },
    searchChange(params, done) {
      console.log(params, "params");
      this.query = params;
      this.page.currentPage = 1;
      this.onLoad(this.page, params);
      done();
    },
    searchReset() {
      this.query = {};
      this.onLoad(this.page);
    },
    selectionClear() {
      this.selectionList = [];
      this.$refs.crud.toggleSelection();
    },
    currentChange(currentPage) {
      this.page.currentPage = currentPage;
    },
    sizeChange(pageSize) {
      this.page.pageSize = pageSize;
    },
    refreshChange() {
      this.onLoad(this.page, this.query);
    },
    onLoad(page, params = {}) {
      this.loading = true;
      let current = page.currentPage;
      let size = page.pageSize;
      getBrandList(current, size, Object.assign(params, this.query)).then(
        (res) => {
          this.page.total = res.data.data.total;
          this.data = res.data.data.records;
          // for (let i = 0; i < this.data.length; i++) {
          //   this.$set(this.data[i], "index", i + 1);
          // }
          this.loading = false;
          // this.selectionClear();
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .avue-crud__tip {
  display: none;
}

::v-deep .el-col-md-24 {
  width: 75% !important;
}

::v-deep .avue-crud__right {
  button:nth-child(n + 2) {
    display: none !important;
  }
}

::v-deep .avue-crud__left {
  // width: 300px !important;
  div {
    display: inline-block;
  }
}

::v-deep .el-card__body {
  padding: 20px 20px 0 20px !important;
}
</style>
